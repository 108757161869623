//93.127.185.88
export const backendConfig = {
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://backend.lavishkallp.com/api/v1"
      : // "https://erp.demo.hopnet.co.in/api/v1"
        "http://192.168.1.2:4000/api/v1",
};

export const imageBaseUrl = backendConfig.baseURL + "/static-file/image";
export const DefaultFontSize = "min(14px, calc(0.5vw + 0.8vh + 0.5vmin))";
export const APP_LOGO_URL =
  "/branch_static_constants/01HXTW16YGZ0F3K49K50JH8TYQ/logo/logo.png";

export const enum TextConstants {
  RUPPEE_SYMBOL = "₹ ",
}

export const enum DCTypes {
  Sample = "Delivery Challan (Sample)",
  Replacement = "Delivery Challan (Replacement)",
  Standard = "Delivery Challan",
}

export const CompanyName = {
  companyName: "Lavishka Partners LLP",
  billingName: "Lavishka Partners LLP",
  // billingName: "Hopnet Demo ERP",
  // companyName: "Hopnet Demo ERP",
  accountNumber: "99999499999995",
  ifscCode: "HDFC0003947",
  branch: "JAYABHERI ENCLAVE",
  // branch: "SP Road, Secunderabad",
};

export const auth = {
  signInUrl: "api/auth/signin",
  changePassword: "/api/user/password/update",
  resetPassword: "api/admin/user/resetPassword/",
  changeDefaultPassword: "/api/user/password/changeDefaultPassword",
};

export const userManagementServices = {
  createUser: "api/admin/user/create/",
  listOfUsers: "api/admin/user/list/all/",
  userFilterOptions: "api/admin/user/filterOptions/",
  userConstants: "api/admin/user/constants",
  userSearchQuery: "api/admin/user/search/",
  userListCount: "api/admin/user/list/count/",
  disableUser: "api/admin/user/disable/",
  enableUser: "api/admin/user/enable/",
  updateUser: "api/admin/user/update/",
  deleteUser: "api/admin/user/delete/",
};

export const skuServices = {
  skuCreate: "api/sku/create",
  getSkuList: "api/sku/list/all/",
  getSkuCount: "api/sku/list/count/",
  deleteSKU: "api/sku/delete/",
  updateSKU: "api/sku/update/",
  getSkuDetails: "api/sku/detail/",
  searchSku: "api/sku/search/",
  createCustomField: "api/sku/create/customField/",
  updateCreatedCode: "api/sku/update/createdCode/",
  getAllSkuCodes: "api/sku/codes",
  updateCostPrice: "api/sku/costPrice/",
  editImage: "api/sku/update/image/",
};

export const clientServices = {
  getClientList: "api/client/list/all/",
  getClientCount: "api/client/list/count/",
  getClientDetail: "api/client/detail/",
  clientUpdate: "api/client/update/",
  deleteClient: "api/client/delete/",
  createClient: "api/client/create/",
  clientDetails: "api/client/detail/",
  getQuotesOfProject: "api/client/project/quote/",
  createProject: "api/client/project/create/",
  deleteProject: "",
  updateProject: "api/client/project/update/",
  searchClient: "api/client/search/",
};

export const QuoteServices = {
  createQuote: "api/quote/create/",
  getQuotationsList: "api/quote/list/all/",
  getQuotationCount: "api/quote/list/count/",
  getProductsList: "/api/sku/list/all/quote/",
  getQuoteDetails: "api/quote/detail/",
  getConstantsForQuote: "api/quote/constants/",
  acceptedStatus: "api/quote/approve/",
  rejectedStatus: "api/quote/reject/",
  closeStatus: "api/quote/close/",
  updateProductList: "api/quote/update/productList",
  updateQuote: "api/quote/update/",
  costPrice: "api/quote/costPrice/",
  paymentReceived: "api/quote/paymentReceived/",
  defaults: "api/quote/defaults/",
  getPoForQuote: "api/quote/list/all/po/",
  getDcForQuote: "api/quote/list/all/dc/",
  generateMasterQuote: "api/quote/masterQuote/",
};

export const BoqServices = {
  createBoq: "api/boq/create/",
  getBoqDetailsByQuoteId: "api/boq/detail/",
  update: "api/boq/update/",
};

export const createQuoteServices = {
  getClientListForQuotes: "api/client/list/all/quote/",
  getUsersListForQuotes: "api/user/list/all/quote/",
};

export const PoServices = {
  getPoList: "api/po/list/all/",
  getPoListCount: "api/po/list/count/",
  getPoDetails: "api/po/detail/",
  verifySuccessfull: "api/po/verify/success",
  verifyFailed: "api/po/verify/failed",
};

export const DeliveryChallanServices = {
  creatDeliveryChallan: "api/dc/create/",
  createSampleDC: "api/dc/create/sample",
  createReplaceDC: "api/dc/create/replacement",
  getDcList: "api/dc/list/all/",
  getCountForDcList: "api/dc/list/count/",
  getDcDetails: "api/dc/detail/",
  editDcDetails: "api/dc/update/",
  receiptUpload: "api/dc/upload/receipt/",
  closeDC: "api/dc/close/",
};

export const WarehouseServices = {
  getPoList: "api/po/list/all/",
  getPoListCount: "api/po/list/count/",
};

export const colorPalette = {
  primaryColor: "#1976d2",
};

export const PAGE_SIZE = 10;

export const ITEM_HEIGHT = 48;
export const QUANTITY_TYPE = ["mtr", "sqft", "qty", "ft"];

export enum IpRating {
  "IP 20" = "IP 20",
  "IP 65" = "IP 65",
  "IP 68" = "IP 68",
}

export enum EWarranty {
  "1 Year" = "1 Year",
  "3 Year" = "3 Year",
  "5 Year" = "5 Year",
  "N/A" = "N/A",
}

export const BEAM_ANGLE = ["15", "24", "38", "40", "60", "110", "120"];
export const BANK_LIST = ["SBI", "HDFC", "Yes Bank", "HSBC", "ICICI", "Kotak"];
// export enum UserType {
//   Employee = "employee",
//   Admin = "admin",
//   Owner = "owner",
//   WarehouseEmployee = "warehouseEmployee",
// }

export enum BoqType {
  sample = "sample",
  boq = "boq",
}

export const Colors = {
  status_closed: "#80808095",
  primary: "#36b8ff",
};
